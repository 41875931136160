import React, { ImgHTMLAttributes } from 'react';
import { getAvatarOrPlaceholder } from 'lib/utils/imageUtils';
import { ProCircleIcon } from 'elements/ProCircleIcon';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  userId: number;
  isPro: boolean;
  hideProBadge?: boolean;
}

export const Avatar = ({
  userId,
  isPro,
  hideProBadge,
  src,
  alt,
  ...restProps
}: Props) => {
  const imgAlt = alt || `${userId}'s avatar`;
  const imgUrl = getAvatarOrPlaceholder(src, userId);
  const showPro = isPro && !hideProBadge;

  const renderProBadge = () => {
    if (!showPro) return null;

    return (<div className="absolute w-1/4 bottom-1 right-1"><ProCircleIcon /></div>);
  };

  //if the image is broken, use a placeholder
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const cleanUserId = userId || Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
    e.currentTarget.src = `/avatars/avatar-${(cleanUserId % 4) + 1}.png`;
  };

  return (
    <div className={`relative rounded-full bg-gradient-to-r w-fit from-[#DAA76E] to-gold ${showPro && 'p-1'}`}>
      <img
        className={`rounded-full ${showPro && 'border border-white'}`}
        alt={imgAlt}
        src={imgUrl}
        loading="lazy"
        {...restProps}
        onError={handleError}
      />
      {renderProBadge()}
    </div>
  );
};
